import React from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = ({
  className = 'button-primary',
  type,
  children,
  ...props
}) => (
  <button type={type || 'button'} className={className} {...props}>
    {children}
  </button>
);

export default Button;
