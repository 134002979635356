import { useEffect, useState } from 'react';
import axios from 'axios';

import { QuuVerification } from '@ausio/types';

export const useRecentVerifications = (): {
  loading: boolean;
  loaded: boolean;
  verifications: Array<QuuVerification> | null;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [verifications, setVerifications] =
    useState<Array<QuuVerification> | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (verifications || loading) return;

    setLoading(true);
    setVerifications(null);
    axios.get('/api/verification').then((response) => {
      setVerifications(response.data as Array<QuuVerification>);
      setLoading(false);
      setLoaded(true);
    });
  }, [verifications, loading]);

  return {
    loading,
    loaded,
    verifications,
  };
};
